<script>
import BaseInput from "@/components/BaseInput.vue";

export default {
  name: "AboutMe",

  data() {
    return {
      email: "",
    };
  },

  components: {
    BaseInput,
  },
};
</script>
<template>
  <div id="contact-me-page">
    <section class="page-title page-title-1 image-bg overlay">
      <div class="background-image-holder">
        <img
          alt="Background Image"
          class="background-image"
          src="@/index/assets/img/meet-kristen.jpg"
        />
      </div>
      <div class="container">
        <div class="row">
          <div class="col-sm-12 text-center">
            <h1 class="uppercase mb0">Meet Cindy</h1>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-8 col-md-offset-2">
            <div class="row mb32">
              <div class="col-sm-5 text-center mb-xs-48">
                <img
                  alt="Background Image"
                  class="background-image mb32"
                  src="@/index/assets/img/about-cindy.jpg"
                />
              </div>
              <div class="col-sm-6 col-sm-offset-1">
                <p class="lead">
                  Reliable, hardworking and unfailingly professional, Cindy is
                  both a proven go-getter and a calming influence in the complex
                  world of real estate. With top-notch communication skills and
                  a keen sense of when to take the lead and when to collaborate,
                  she tirelessly strives to exceed her clients' expectations and
                  guide them to success.
                </p>
                <p class="lead">
                  Cindy came to real estate after a successful marketing career
                  specializing in strategic planning, branding and account
                  management, which has proven to be a great asset to her real
                  estate clients. As a member of the Compass team, she applies
                  her well-rounded skill set to serve both buyers and sellers
                  with a tactical mindset and exceptional marketing talents.
                  Above all, she strives to arm clients with everything they
                  need to make smart, successful decisions.
                </p>
                <p class="lead">
                  Originally from Philadelphia, Cindy earned a bachelor's degree
                  from Southern Methodist University before settling in San
                  Francisco more than two decades ago. She resides in Pacific
                  Heights where she adores the stunning architecture, fantastic
                  parks and lovely streetscapes. When she's not serving the
                  needs of her clients, you can find Cindy hiking, seeing live
                  music, traveling and enjoying festive dinners with close
                  friends.
                </p>
                <p>
                  <br />
                  <strong style="font-size: 14px">
                    Cindy Manning
                  </strong>
                  <br />
           <span
              >M: <a href="tel:415-385-1031">415-385-1031</a>&nbsp; |
              &nbsp;DRE#: 02086335</span
            >
                  <br />
                  <span
                    >E:
                    <a href="mailto:cindy.manning@compass.com"
                      >cindy.manning@compass.com</a
                    >
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <!--end of row-->
      </div>
      <!--end of container-->
    </section>
    <!-- <section class="image-bg overlay">
      <div class="background-image-holder">
        <img
          alt="Background Image"
          class="background-image"
          src="@/index/assets/img/meet-kristen-top.jpg"
        />
      </div>
      <div class="container">
        <div class="row form-row">
          <div class="col-sm-6 text-center contact-me-col">
            <h3>Work with Cindy</h3>
            <p class="lead mb64">
              Cindy has more than a decade of Bay Area real estate sales
              experience and over $400 million in closed transactions. She has
              continuously ranked with top San Francisco real estate
              professionals in total sales production and among leading agents
              nationwide- all while having fun doing it!
            </p>
            <router-link to="/contact-us" class="btn btn-white btn-lg"
              >Contact Us</router-link
            >
          </div>
          <div class="col-sm-6 text-center newsletter-col">
            <h3>Newsletter</h3>
            <p class="lead">
              For exclusive news and market updates sign up for our newsletter.
            </p>
            <form action="">
              <div class="form-group">
                <BaseInput
                  v-model="email"
                  type="text"
                  class="validate-required validate-email"
                  placeholder="Email"
                />
              </div>
              <button class="btn btn-white btn-lg">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </section> -->
  </div>
</template>
<style lang="scss">
.form-row {
  display: flex;
  align-items: center;
  justify-content: center;
}
#contact-me-page {
  .contact-me-col {
    padding: 64px;
    background: #383838b5;
  }

  .newsletter-col {
    border: 1px solid #e8e8e8;
    border-width: 1px 1px 1px 0;
    padding: 104px 64px;
  }
}
</style>
